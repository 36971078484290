const Header = () => {
    return(
        <div>
            {/* <div class="menu">
                <div class="menulogo">
                    <div class="logo">
                    GANAMEX
                    </div>
                    
                </div>
            </div> */}
            <div class="title-site">
                Calcula tu presupuesto
            </div>
        </div>
        
    )
}

export default Header;